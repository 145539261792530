<template>
    <div class="assess-target-grid">
        <sn-table-group class="table" :tableGroupAttributes="tableGroupAttributes" @handleChange="handleChange"></sn-table-group>
        <sn-dialog :dialogData.sync="dialogData">
            <template>
                <FileDialog :dataSource="currentData" :fileList="fileList"></FileDialog>
            </template>
        </sn-dialog>
    </div>
</template>
<script>
import { commonData } from '../sn-table-group-common';
import { selfData } from './model/1_sn-table-group';
import commonMethodMixin from '../commonMethodMixin';
import { mapActions } from 'vuex';
import FileDialog from './file-dialog'
export default {
    name: 'report_manage_grid',
    mixins: [commonMethodMixin],
    data() {
        return {
            commonData,
            selfData,
            infoPage: 'report_manage_form',
            // 筛选项
            keyword: '', // 关键字
            dxlxValue: '', //对象类型选择的值
            dialogData: {
                title: '数据查看',
                dialog: false,
                width: '',
                class: 'deepBlueDialog',
                action: false,
            },
            currentData: {},
            fileList: [],
        };
    },
    components:{
        FileDialog
    },
    mounted() {
        this.getDxlxList();
    },
    methods: {
        ...mapActions([
            'GetEnumItem', //获取枚举列表
            'GetReportList', //获取报告列表
            'DelReport', //删除报告
        ]),
        async getTableData() {
            // 处理筛选
            this.dxlxValue = '';
            if (this.searchData && this.searchData.length > 0) {
                this.searchData.forEach((item) => {
                    if (
                        (typeof item.value === "string" && item.value !== "") ||
                        (typeof item.value === "object" &&
                            Object.keys(item.value).length > 0)
                    ) {
                        if (item.type === "BGLX") {
                            this.dxlxValue = item.value;
                        }
                    }
                });
            }
            
            this.tableGroupAttributes.loading = true;
            let res = await this.GetReportList({
                keyword: this.keyword, //String 内存放评估对象名称
                lx: this.dxlxValue, //String 内存放评估对象类型
                pageindex: this.tableGroupAttributes.snPage.currentPage,	//Int 页数
                pagesize: this.tableGroupAttributes.snPage.tiaoshu,	//Int 页面数据量
            })
            this.tableGroupAttributes.snTable.table.data = res.DATA || [];
            this.tableGroupAttributes.snPage.count = Number(res.TOTAL) || 0;
            this.tableGroupAttributes.loading = false;

            this.tableGroupAttributes.snTable.columns.columnsData[
                this.tableGroupAttributes.snTable.columns.columnsData.length - 1
            ] = {
                fileText: true,
                prop: "FILE",
                label: "附件",
                minWidth: "160",
                colorStr: '#0066ff',
                textDecoration: 'underline',
            };
            // this.tableGroupAttributes.snTable.columns.columnsData[
            //     this.tableGroupAttributes.snTable.columns.columnsData.length - 1
            // ] = {
            //     label: "数据查看",
            //     prop: "DATAFILE",
            //     width: "100",
            //     isReplaceText: true,
            //     replaceText: '查看',
            //     replaceChar: '--',
            //     textStyle: {
            //         color: '#2a62d5',
            //         cursor: 'pointer'
            //     },
            //     operateType: "columnText"
            // };
        },
        // 获取报告类型列表
        async getDxlxList(){
            this.tableGroupAttributes.snSearch.selectData[0].list = [];
            let result = await this.GetEnumItem({
                Enumid: 'cf24c70d-94c3-11ea-bd8d-000c2977b7fd'
            });
            if(result && result.length>0){
                this.tableGroupAttributes.snSearch.selectData[0].list = result;
            }
        },
        // 删除数据
        async delData(id, done) {
            let res = await this.DelReport({
                ids: id
            });
            this.delOver(res, done);
        },
        delOver(res, done) {
            if (res.ISSUCCESS) {
                done();
                this.$message({
                    message: '删除成功',
                    type: 'success'
                });
                // this.tableGroupAttributes.snPage.currentPage = 1;
                this.getTableData();
            } else {
                this.$message({
                    message: '删除失败',
                    type: 'error'
                });
            }
        },
        columnTextClick(val){
            if(val && val.length>0 && val[0].DATAFILE && val[0].DATAFILE.length>0){
                this.dialogData.dialog = true;
                this.currentData = val[0];
                this.fileList = val[0].DATAFILE;
            }
        },
        async exportData() {
            let url = window.REQUEST_URL + 'EvaluationAnalysis/ExportReport';
            let ywbysjid = '9998';
            let token = localStorage.accessToken;
            // let exportUrl = `${url}?Code=${Code}&token=${token}`;
            let exportUrl = `${url}?ywbysjid=${ywbysjid}&token=${token}`;
            window.open(exportUrl);
        },
       
    },
};
</script>
<style lang="scss" scoped>
.assess-target-grid{
    width: 100%;
    height: 100%;
    .table{
        width: 100%;
        height:calc(100% - 150px);
    }
}
</style>