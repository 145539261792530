<template>
    <div class="assess-result">
        <div class="assess-p">
            <div v-for="(item,index) in pgbgList" :key="index">
                <a class="assess-a" :href="item.CCLJ" target="_blank">{{item.WDMC}}</a>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
    data() {
        return {
            pgjgjsText: '',
            pgbgList: [],
        }
    },
    props:{
        dataSource: {
            type: Object,
            default: () => { }
        },
        fileList: {
            type: Array,
            default: () => {}
        }
    },
    watch: {
        dataSource() {
            this.getAssessData();
        },
        // 临时：不知道直接传，还是调服务
        fileList() {
            this.getAssessData();
        }
    },
    mounted() {
        this.getAssessData();
    },
    methods: {
        ...mapActions([
            'GetDealProcessExpert', //获取专家评估结果信息
        ]),
        async getAssessData() {
            this.pgbgList = this.fileList;

            // return;
            // this.pgjgjsText = '';
            // this.pgbgList = {};
            // if (this.dataSource && this.dataSource.ID) {
            //     let result = await this.GetDealProcessExpert({
            //         zjrwid: this.dataSource.ID
            //     });
            //     if(result && result.length>0){
            //         this.pgjgjsText = result[0].Zjjy;
            //         this.pgbgList = result[0].PGFJ;
            //     }
            // }
        },
    },
}
</script>
<style lang="scss" scoped>
.assess-result {
    width: 100%;
    padding: 10px;
    .assess-p {
        line-height: 24px;
        .assess-a {
            color: #00f;
            cursor: pointer;
        }
    }
}
</style>
