const selfData = {
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [{
                label: "报告名称",
                prop: "BGMC",
                minWidth: 200
            }, {
                label: "报告类型",
                prop: "BGLX_DESC",
                width: 120
            },

            // {
            //     label: "对象类型",
            //     prop: "DXLX_DESC",
            //     width: 120
            // }, {
            //     label: "对象",
            //     prop: "PGDXMC",
            //     width: 200
            // },
            {
                label: "内容概要",
                prop: "NRJY",
                minWidth: 380
            }, {
                label: "负责人",
                prop: "FZRXM",
                width: 200
            },
            {
                label: "报告生成时间",
                prop: "CJSJ",
                width: 150,
                substr: true,
            },
            {
                label: "附件",
                prop: "FILE",
                minWidth: 120
            },
                // {
                //     label: "数据查看",
                //     prop: "DATAFILE",
                //     width: 100
                // }
            ],
            // 操作列
            columnsBtn: {
                buttonData: [{
                    //     isShow: true,
                    //     btnType: 'icon',
                    //     operateType: 'columnRead',
                    //     title: '查看',
                    //     icon: 'el-icon-view',
                    //     color: 'green',
                    // }, {
                    isShow: true,
                    btnType: 'icon',
                    operateType: 'columnEdit',
                    title: '修改',
                    icon: 'el-icon-edit',
                    color: 'green',
                    // showMethod: 'showEditBtn',
                },
                {
                    isShow: true,
                    btnType: 'icon',
                    operateType: 'reportUp',
                    title: '报告',
                    icon: 'el-icon-document',
                    color: 'green',
                    // showMethod: 'reportUp',
                },
                {
                    isShow: true,
                    btnType: 'icon',
                    operateType: 'columnDelete',
                    title: '删除',
                    icon: 'el-icon-delete',
                    color: 'red',
                    // showMethod: 'showDelBtn',
                }],
                width: 150,
            }
        },
    },
    snPage: {
        tiaoshu: 20,
        count: 0,
        currentPage: 1,
        operateType: 'page'
    },
    snSearch: {
        selectData: [{
            label: '报告类型',
            clearable: true,
            placeholder: "请选择报告类型",
            list: [],
            optionLabel: 'Name',
            optionValue: 'Code',
            value: 'BGLX',
            operateType: 'search',
            isShow: true
        }],
    },
    snButton: {
        buttonData: [{
            isShow: true,
            btnType: 'button',
            operateType: 'buttonNew',
            name: '新建',
            round: true,
            backColor: '#28ccd9',
        }, {
            btnType: 'button',
            operateType: 'buttonExport',
            name: '导出',
            round: true,
            backColor: '#ffcc66',
            color: '#fff'
        }]
    }
};
export { selfData };